import request from "@/api/service";

const api = {
   page: '/order/order-refund-apply/getPage',
   agree: '/order/order-refund-apply/agreeRefund',
   reject: '/order/order-refund-apply/rejectRefund'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function agreeRefund(type, params) {
   return request({
      url: api[type],
      method: 'POST',
      data: params
   })
}
